import React, { useState } from "react";
import { Link } from "react-router-dom";
import ProductLoader from "./Includes/ProductLoader";
import { Tooltip } from "react-tooltip";

export default function SingleProductsWithVariants({
  el,
  productAddInCart,
  disable,
}) {
  const [selectedVariant, setSelectedVariant] = useState(
    el.has_variants && el.variations?.length ? el.variations : []
  );

  const selectedSeted = (variant) => {
    if (selectedVariant?.length && variant && el?.variations?.length) {
      const filteredArray = el?.variations?.filter(
        (item) => item.id === variant
      );

      setSelectedVariant(filteredArray);
    }
  };

  return (
    <div className="product-wrap">
      <div className="product-img">
        {el.variations?.length ? (
          <div className="small-variant-img">
            {el.variations.map((vart) => (
              <img
                src={vart.image}
                alt="variant Image"
                key={vart.id}
                onClick={() => {
                  if (selectedVariant[0]?.id !== vart.id) {
                    selectedSeted(vart.id);
                  }
                }}
              />
            ))}
          </div>
        ) : null}

        <Link to={`/product-detail/${el.id}/${selectedVariant[0]?.id}`}>
          <ProductLoader
            cardImg={selectedVariant[0]?.image}
            has_variants={el.has_variants}
          />
        </Link>
        <div className={`controls only-one`}>
          <button
            onClick={() => {
              productAddInCart(
                el.id,
                selectedVariant[0]?.size,
                selectedVariant[0]?.color
              );
            }}
            disabled={disable}
            data-tooltip-id={el.id}
            data-tooltip-content="Add to Cart!"
            data-tooltip-place="top"
          >
            <i className="fa fa-shopping-cart"></i>
          </button>

          <Tooltip
            id={el.id}
            style={{
              padding: "2px 10px",
            }}
          />

          <Link
            to={`/product-detail/${el.id}/${selectedVariant[0]?.id}`}
            data-tooltip-id={el.id}
            data-tooltip-content={"View Details!"}
            data-tooltip-place="top"
          >
            <i className="fa fa-external-link"></i>
          </Link>
        </div>
      </div>
      <div className="product-name" title={el.name}>
        <span>
          {el.name} - {selectedVariant[0]?.color}
        </span>
        {/* <span className="price">Points: {el.points}</span> */}
        {/* <div className="size-list">
          {selectedVariant?.map(
            (vart) =>
              vart.id === selectedVariant[0].id &&
              vart?.sizes?.map((size) => (
                <span
                  key={size}
                  className={`${
                    selectedVariant[0].size === size ? "active" : ""
                  }`}
                  onClick={() => {
                    setSelectedVariant((prev) => {
                      const updatedVariant = [...prev]; 
                      updatedVariant[0] = { ...updatedVariant[0], size: size }; 
                      return updatedVariant;
                    });
                  }}
                >
                  {size}
                </span>
              ))
          )}
        </div> */}
      </div>
    </div>
  );
}
