import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";

import ReactLoading from "react-loading";

import { useFormik } from "formik";

import { CKEditor } from "ckeditor4-react";
import { updateNewsleter } from "../../../schema";
import Sidebar from "../includes/Sidebar";
import MainHeading from "../includes/MainHeading";
import Footer from "../includes/Footer";
import AdminListService from "../../../services/admin-list.service";
import he from "he";

export default function ManageNewsletter() {
  const [loading, setLoading] = useState(false);
  const [editorData, setEditorData] = useState("");

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();

  useEffect(() => {
    if (adminAccessToken) {
      const getNewsletterById = async () => {
        setLoading(true);
        try {
          const response = await AdminListService.getNewsletter("1");

          setFieldValue("body", he.decode(response?.data?.response?.body));
          setFieldValue("subject", response?.data?.response?.subject);
          const bodyData = he.decode(response?.data?.response?.body);
          setEditorData(bodyData);

          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }
      };
      getNewsletterById();
    } else {
      navigate("/admin/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const updateNewsletter = async (values) => {
    setLoading(true);
    try {
      const response = await AdminListService.newsletterUpdate(
        values,
        admin_id,
        "1"
      );
      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);

      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.error(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,

    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      subject: "",
      body: "",
    },
    validationSchema: updateNewsleter,
    onSubmit: (values, action) => {
      updateNewsletter(values);
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Manage Newsletter" />
          <div className="row">
            <div className="mt-4 col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Newsletter</h4>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-md-12">
                      <div className="input-field-box">
                        <label>
                          Subject <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Subject"
                          onChange={handleChange}
                          name="subject"
                          value={values.subject || ""}
                        />
                        {errors.subject && touched.subject ? (
                          <span className="text-danger">{errors.subject}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="font-17">Message Body</label>
                      {editorData && (
                        <CKEditor
                          initData={editorData}
                          config={{
                            allowedContent: true,
                            versionCheck: false,
                            toolbar: [
                              ["Source"],
                              ["Styles", "Format", "Font", "FontSize"],
                              [
                                "Cut",
                                "Copy",
                                "Paste",
                                "PasteText",
                                "PasteFromWord",
                                "-",
                                "Undo",
                                "Redo",
                              ],
                              [
                                "Bold",
                                "Italic",
                                "Strike",
                                "UnderLine",
                                "RemoveFormat",
                              ],
                              ["Link", "Unlink", "Anchor"],
                              [
                                "NumberedList",
                                "BulletedList",
                                "-",
                                "Outdent",
                                "Indent",
                                "-",
                                "Blockquote",
                                "CreateDiv",
                                "-",
                                "JustifyLeft",
                                "JustifyCenter",
                                "JustifyRight",
                                "JustifyBlock",
                                "-",
                                "BidiLtr",
                                "BidiRtl",
                                "Language",
                              ],
                              ["EasyImageUpload"],
                              ["TextColor", "BGColor"],
                              ["Maximize", "ShowBlocks"],
                            ],
                            extraPlugins: "easyimage",
                            removePlugins: "image",
                            cloudServices_uploadUrl:
                              "https://33333.cke-cs.com/easyimage/upload/",
                            cloudServices_tokenUrl:
                              "https://33333.cke-cs.com/token/dev/ijrDsqFix838Gh3wGO3F77FSW94BwcLXprJ4APSp3XQ26xsUHTi0jcb1hoBt",
                          }}
                          onInstanceReady={(e) => {}}
                          onChange={(e) => {
                            var data = e.editor.getData();

                            setFieldValue("body", data);
                          }}
                        />
                      )}
                      {errors.body && touched.body ? (
                        <span className="text-danger">{errors.body}</span>
                      ) : null}
                    </div>

                    <div className="col-12">
                      <button className="btn btn-primary mt-3" type="submit">
                        Update Newsletter
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {loading && (
        <ReactLoading
          type="spinningBubbles"
          color="#185791"
          className="bar-loader"
        />
      )}
    </div>
  );
}
