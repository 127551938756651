import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import HeaderUser from "./Includes/HeaderUser";
import Shipping from "./Includes/Shipping";
import FooterUser from "./Includes/FooterUser";
import UserListService from "../../services/user-list.service";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import { actionCreaters } from "../../Redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import ReactLoading from "react-loading";

import $ from "jquery";
import { useFormik } from "formik";
import { orderForVoucher } from "../../schema";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";

export default function CartPage() {
  const [tableLoader, settableLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [item, setItem] = useState(false);
  const [agree, setAgree] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [errorsServer, setErrorsServer] = useState([]);
  const [formSubmiting, setFormSubmiting] = useState(false);

  const [tableData, settableData] = useState([]);

  const state = useSelector((state) => state.stateVals);
  const { certificate } = state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  var clickEvent = $.Event("click");

  const cartList = async () => {
    settableLoader(true);

    try {
      const responce = await UserListService.getCartList(certificate);

      const res = responce?.data?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          id: value.id,
          prod_id: value.prod_id,
          image: value.image,
          description: value.description,
          name: value.name,
          variant: value.variant || null,
        });
      });

      settableData([...results]);

      settableLoader(false);
      setItem(false);
    } catch (err) {
      console.log(err);

      settableData([]);

      settableLoader(false);
      setItem(true);
    }
  };

  useLayoutEffect(() => {
    if (certificate) {
      cartList();
      setShow(true);
    } else {
      navigate("/", { replace: true });
      setTimeout(() => {
        setShow(true);
      }, 500);
    }
  }, []);

  const delCartItem = async (e, delId) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete this record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        settableLoader(true);
        try {
          await UserListService.deletSingleCartItem(delId);
          userActions.cartCounter(certificate, "cartCounter");

          cartList();

          Swal.fire({
            icon: "success",
            title: "Record has been deleted!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };
  const delCart = async () => {
    settableLoader(true);
    try {
      const response = await UserListService.deletCart(certificate);
      userActions.cartCounter(certificate, "cartCounter");

      cartList();
    } catch (err) {
      console.log(err);
    }
  };

  const statesLists = async () => {
    try {
      const responce = await UserListService.getAllStates();

      const res = responce?.data?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          label: value.name,
          value: value.name,
        });
      });
      await setCardData([]);
      setTimeout(() => {
        setCardData([...results]);
      }, 500);
    } catch (err) {
      console.log(err);
      setCardData([]);
    }
  };

  const checkCertificate = async () => {
    setFormSubmiting(true);
    try {
      const response = await UserListService.checkCertiStatus(certificate);

      statesLists();
      setFormSubmiting(false);

      $("#modelOpen").trigger(clickEvent);
    } catch (err) {
      setFormSubmiting(false);
      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.warn(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const opemModel = async () => {
    if (agree) {
      $(".alert").slideUp();
      checkCertificate();
    } else {
      $(".alert").slideDown();
    }
  };

  const makeOrder = async (values, action) => {
    setFormSubmiting(true);

    try {
      const response = await UserListService.putOrder(values, certificate);

      action.resetForm();
      $("#exampleModal2Close").trigger(clickEvent);
      cartList();
      setFormSubmiting(false);
      navigate(`/order-detail/${certificate}`, { replace: true });
    } catch (err) {
      console.log(err);
      setFormSubmiting(false);

      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        Swal.fire({
          icon: "warning",
          title: "Warning",
          iconColor: "#e04f5d",
          confirmButtonColor: "#e72e2e",
          text: err?.response?.data?.response,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          confirmButtonColor: "#e72e2e",
          text: "Something went wrong",
        });

        let errorData = {};

        errorData["First"] =
          err.response?.data &&
          err.response?.data?.fname &&
          err.response?.data?.fname[0];
        errorData["Last"] =
          err.response?.data &&
          err.response?.data?.lname &&
          err.response?.data?.lname[0];
        errorData["email"] =
          err.response?.data &&
          err.response?.data?.email &&
          err.response?.data?.email[0];
        errorData["phone"] =
          err.response?.data &&
          err.response?.data?.phone &&
          err.response?.data?.phone[0];

        errorData["City"] =
          err.response?.data &&
          err.response?.data?.city &&
          err.response?.data?.city[0];

        errorData["State"] =
          err.response?.data &&
          err.response?.data?.state &&
          err.response?.data?.state[0];

        errorData["Zip"] =
          err.response?.data &&
          err.response?.data?.zip &&
          err.response?.data?.zip[0];

        errorData["address1"] =
          err.response?.data &&
          err.response?.data?.address1 &&
          err.response?.data?.address1[0];

        errorData["res_address"] =
          err.response?.data &&
          err.response?.data?.res_address &&
          err.response?.data?.res_address[0];

        setErrorsServer(errorData);
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          600
        );
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,

    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      First: "",
      res_address: "",
      address1: "",
      State: "",
      Last: "",
      City: "",
      Zip: "",
      email: "",
      phone: "",
    },
    validationSchema: orderForVoucher,
    onSubmit: (values, action) => {
      makeOrder(values, action);
    },
  });

  const handleChangecheckbox = () => {
    setAgree(!agree);
  };

  return show ? (
    <div className="reward-body">
      <HeaderUser />
      <div className="main-content">
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <div className="user-form home_div">
                <h3>Shopping Cart Details</h3>
                <Shipping />
              </div>
              {item ? (
                <div
                  className="alert alert-danger d-flex align-items-center"
                  role="alert"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x-lg me-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                  </svg>

                  <div className="fw-medium">
                    There are no items in your shopping cart!
                  </div>
                </div>
              ) : (
                <form>
                  <table className="table-shop" id="example">
                    <thead>
                      <tr>
                        <th className="product-no">No. </th>
                        <th className="product-image"> Image </th>
                        <th className="product-name"> Item Name </th>

                        <th> Actions </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableLoader ? (
                        <tr>
                          <td colSpan="4" className="skleton-td">
                            <Skeleton width="100%" count={2} height="50px" />
                          </td>
                        </tr>
                      ) : tableData.length ? (
                        <>
                          {tableData.map((el, index) => (
                            <tr key={el.id}>
                              <td>
                                <div className="th_title d-block d-md-none">
                                  {" "}
                                  No.{" "}
                                </div>
                                {index + 1}
                              </td>
                              <td>
                                <div className="th_title d-block d-md-none">
                                  {" "}
                                  Image{" "}
                                </div>
                                <Link
                                  to={
                                    el.variant
                                      ? `/product-detail/${el.prod_id}/${el.variant.id}`
                                      : `/product-detail/${el.prod_id}`
                                  }
                                >
                                  <img
                                    src={
                                      el.variant
                                        ? el.variant?.image
                                        : `${process.env.REACT_APP_IMAGE_Link}${el.image}`
                                    }
                                    className="img-fluid"
                                  />
                                </Link>
                              </td>
                              <td>
                                <div className="th_title d-block d-md-none">
                                  {" "}
                                  Item Name{" "}
                                </div>
                                {el.name}
                                {el.variant &&
                                  ` - ${el.variant.color} - ${el.variant.size}`}
                              </td>

                              <td>
                                <div className="th_title  d-block d-md-none">
                                  {" "}
                                  Action:{" "}
                                </div>
                                <Tooltip
                                  id={el.id}
                                  style={{
                                    padding: "2px 10px",
                                  }}
                                />
                                <a
                                  href="/"
                                  className="red"
                                  onClick={(e) => {
                                    delCartItem(e, el.id);
                                  }}
                                  data-tooltip-id={el.id}
                                  data-tooltip-content="Delete"
                                  data-tooltip-place="top"
                                >
                                  <i className="fa fa-trash-o rect"></i>{" "}
                                </a>
                              </td>
                            </tr>
                          ))}

                          <tr>
                            <td colSpan="4" className="agree padd-zero ">
                              <label>
                                <input
                                  id="sessTerm"
                                  className="sessTerm"
                                  type="checkbox"
                                  onChange={handleChangecheckbox}
                                />{" "}
                                I agree to{" "}
                                <a
                                  type="button"
                                  className="black"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  Terms &amp; Conditions
                                </a>{" "}
                              </label>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>

                  <div
                    className="alert alert-warning  "
                    role="alert"
                    style={{
                      display: "none",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-exclamation-triangle-fill me-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                      </svg>

                      <div className="fw-normal">
                        <span className="fw-bold">Warning!</span> Please Click
                        check box to agree our TERMS AND CONDITIONS.
                      </div>
                    </div>
                  </div>

                  {tableData.length ? (
                    <div className="col-md-12">
                      <div className="row cont">
                        <div className="col-md-3"></div>
                        <div className="col-md-3">
                          <input
                            type="button"
                            className="button"
                            value="Empty Cart"
                            onClick={delCart}
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            className="button"
                            type="button"
                            value="Continue Shopping"
                            onClick={() => {
                              navigate("/viewreward");
                            }}
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            className="button less_points less_points2"
                            type="button"
                            value="Order"
                            onClick={opemModel}
                          />
                          <button
                            type="button"
                            className="d-none invisible"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal2"
                            id="modelOpen"
                          ></button>{" "}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <FooterUser />
      {tableData.length ? (
        <>
          <div
            className="modal fade term-model"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="modal-title">Terms and Conditions</h2>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                  >
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Elite Rewards would like to congratulate you on earning your
                    points. We want this to be an exciting and rewarding
                    experience for you.
                    <br />
                    <br />
                    Below are a few guidelines that will need to be followed to
                    help you when using the Reward Program. Please take a few
                    moments to read through and get familiarized with these
                    guidelines. In doing so, it helps ensure your happy
                    experience.
                    <br />
                    <br />
                    If you have any further questions, please do not hesitate to
                    reach out to our help desk at&nbsp;(866) 766-60358&nbsp;or
                    via email –&nbsp;
                    <a href="mailto:help@eliterewards.biz">
                      help@eliterewards.biz
                    </a>
                  </p>
                  <p></p>
                  <ul>
                    <li>
                      We reserve the right to substitute any product of equal or
                      greater value if product is unavailable.
                    </li>
                    <li>
                      There are no returns on any redemption that have been
                      shipped.
                    </li>
                    <li>
                      Any products damage during shipping should be refused and
                      contact Elite Rewards for immediate replacement.
                    </li>
                    <li>
                      Any defective products or products under warranty are not
                      handled by Elite Rewards, you need to contact the
                      manufacture for replacements.
                    </li>
                    <li>Installation of major appliances is not included.</li>
                    <li>
                      All shipments are PREPAID. Should the carrier, in error,
                      require you to pay for delivery of an item, make the
                      payment; then send your receipt to Award Headquarters,
                      Customer Service Department,&nbsp; Elite Rewards, 5111
                      Central Avenue. St, Petersburg, FL 33710&nbsp;for prompt
                      reimbursement.
                    </li>
                    <li>
                      We will not deliver to a PO Box, APO or outside the 48
                      continuous states.
                    </li>
                    <li>All sales are final.</li>
                    <li>
                      Please allow up to 4 weeks for delivery.&nbsp; Items may
                      be delivered at different times.
                    </li>
                  </ul>
                  <strong>TRAVEL</strong>
                  <br />
                  <ul>
                    <li>
                      Certificates for travel will be shipped following order.
                      Please allow 4-6 weeks
                    </li>
                    <li>
                      35-day advance notice required for all hotel destinations.
                    </li>
                    <li>
                      60-day advance notice required for all airfare, theme park
                      &amp; sports travel.
                    </li>
                    <li>
                      90-day advance notice required for all cruise travel.
                    </li>
                    <li>
                      Black Out Dates – Weeks of Thanksgiving, Christmas, New
                      Years, Memorial Day, and Independence Day. Other holidays
                      may be restricted based on availability given to Elite
                      Rewards.
                    </li>
                    <li>
                      Sales tax, delivery, service charges and all other
                      incidental charges not associated with actual material
                      sale are excluded from incentive dollar credit.
                    </li>
                    <li>
                      Participants may work with Elite Rewards to “upgrade”
                      trip, add days, rental cars, upgrade destination or
                      upgrade stateroom on a cruise.
                    </li>
                    <li>
                      All additional costs will be the responsibility of the
                      traveler. Upgrades are subject to availability.
                    </li>
                    <li>All travel requests are subject to availability.</li>
                    <li>
                      Certain other restrictions apply and are listed on the
                      travel voucher that you will receive upon qualifying and
                      requesting your package.
                    </li>
                    <li>
                      Trips may only be ordered and redeemed as outlined. These
                      promotional trips have no cash value and may not be
                      ordered and redeemed other than as outlined herein.
                    </li>
                    <li>
                      Participants using the trip assume any and all tax
                      liability (if applicable).
                    </li>
                    <li>
                      Void where prohibited by law or local ordinance.
                      <br />
                      <br />
                      Please allow 4-6 weeks to receive all products from the
                      time you receive the confirmation email that the product
                      was ordered. If you have multiple products they may come
                      at different times.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {agree && (
            <div
              className="modal fade term-model"
              id="exampleModal2"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h2 className="modal-title">User Details</h2>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      id="exampleModal2Close"
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="row" onSubmit={handleSubmit} noValidate>
                      <div className="col-md-6">
                        <div className="form-floating mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            onChange={handleChange}
                            name="First"
                            value={values.First || ""}
                            tabIndex="1"
                          />
                          <label>First Name</label>
                        </div>
                        {errors.First && touched.First ? (
                          <span className="text-danger error-message">
                            {errors.First}
                          </span>
                        ) : errorsServer.First ? (
                          <span className="text-danger error-message">
                            {errorsServer.First}
                          </span>
                        ) : null}
                      </div>

                      <div className="col-md-6">
                        <div className="form-floating mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            onChange={handleChange}
                            name="Last"
                            value={values.Last || ""}
                            tabIndex="2"
                          />

                          <label>Last Name</label>
                          {errors.Last && touched.Last ? (
                            <span className="text-danger error-message">
                              {errors.Last}
                            </span>
                          ) : errorsServer.Last ? (
                            <span className="text-danger error-message">
                              {errorsServer.Last}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-floating mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Residential Address"
                            onChange={handleChange}
                            name="res_address"
                            value={values.res_address || ""}
                            tabIndex="3"
                          />
                          <label>Residential Address</label>
                          {errors.res_address && touched.res_address ? (
                            <span className="text-danger error-message">
                              {errors.res_address}
                            </span>
                          ) : errorsServer.res_address ? (
                            <span className="text-danger error-message">
                              {errorsServer.res_address}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address2"
                            onChange={handleChange}
                            name="address1"
                            tabIndex="4"
                            value={values.address1 || ""}
                          />
                          <label>Address2</label>
                          {errors.address1 && touched.address1 ? (
                            <span className="text-danger error-message">
                              {errors.address1}
                            </span>
                          ) : errorsServer.address1 ? (
                            <span className="text-danger error-message">
                              {errorsServer.address1}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-floating mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            onChange={handleChange}
                            name="City"
                            tabIndex="5"
                            value={values.City || ""}
                          />
                          <label>City</label>
                          {errors.City && touched.City ? (
                            <span className="text-danger error-message">
                              {errors.City}
                            </span>
                          ) : errorsServer.City ? (
                            <span className="text-danger error-message">
                              {errorsServer.City}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box mt-3 mb-0">
                          {cardData.length ? (
                            <Select
                              classNamePrefix="react-select"
                              className={`select_1 dropdown`}
                              placeholder="Choose State"
                              options={cardData}
                              isSearchable={true}
                              isClearable={true}
                              // menuIsOpen={true}
                              tabIndex="6"
                              name="State"
                              onChange={(selectedOption) => {
                                if (selectedOption) {
                                  setFieldValue("State", selectedOption.value);
                                } else {
                                  setFieldValue("State", "");
                                }
                              }}
                            />
                          ) : (
                            <Skeleton width="100%" count={1} height="55px" />
                          )}
                          {errors.State && touched.State ? (
                            <span className="text-danger error-message">
                              {errors.State}
                            </span>
                          ) : errorsServer.State ? (
                            <span className="text-danger error-message">
                              {errorsServer.State}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ZIP Code"
                            onChange={handleChange}
                            name="Zip"
                            value={values.Zip || ""}
                            tabIndex="7"
                          />
                          <label>ZIP Code</label>
                          {errors.Zip && touched.Zip ? (
                            <span className="text-danger error-message">
                              {errors.Zip}
                            </span>
                          ) : errorsServer.Zip ? (
                            <span className="text-danger error-message">
                              {errorsServer.Zip}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-floating mt-3">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email Address"
                            onChange={handleChange}
                            name="email"
                            value={values.email || ""}
                            tabIndex="8"
                          />
                          <label>Email Address</label>
                          {errors.email && touched.email ? (
                            <span className="text-danger error-message">
                              {errors.email}
                            </span>
                          ) : errorsServer.email ? (
                            <span className="text-danger error-message">
                              {errorsServer.email}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone"
                            onChange={handleChange}
                            name="phone"
                            value={values.phone || ""}
                            tabIndex="9"
                            maxLength={10}
                          />
                          <label>Phone</label>
                          {errors.phone && touched.phone ? (
                            <span className="text-danger error-message">
                              {errors.phone}
                            </span>
                          ) : errorsServer.phone ? (
                            <span className="text-danger error-message">
                              {errorsServer.phone}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3 ms-auto mt-3">
                        <input
                          className="button less_points less_points2"
                          type="submit"
                          value="Place Order"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : null}
      {formSubmiting && (
        <ReactLoading
          type="spinningBubbles"
          color="#185791"
          className="bar-loader"
        />
      )}
    </div>
  ) : (
    <div className="white-screen"></div>
  );
}
