import React, { useEffect, useState } from "react";
import Logo from "../../Images/logo.webp";
import binoculars from "../../Images/binoculars.png";
import mouse from "../../Images/mouse.png";
import question from "../../Images/question.png";
import shipping from "../../Images/shipping.png";
import spam from "../../Images/spam.png";
import ReactLoading from "react-loading";

import "./style.css";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import { validateVoucher } from "../../schema";
import { toast } from "react-toastify";
import UserListService from "../../services/user-list.service";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { actionCreaters } from "../../Redux";
import { bindActionCreators } from "redux";
import secureLocalStorage from "react-secure-storage";

export default function Home() {
  const state = useSelector((state) => state.stateVals);
  const { certificate } = state;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const onCaptchaChange = (value) => {
    if (value === null) {
      setFieldValue("captchaValidate", false);
    } else {
      setFieldValue("captchaValidate", true);
    }
  };

  const voucherEnter = async (values) => {
    setLoading(true);
    try {
      const response = await UserListService.voucherCheck(values.voucher);

      await userActions.storeVoucher(
        {
          certificate: response?.data?.response?.certificate,
          expiration: response?.data?.response?.expiration,
        },
        "voucherSave"
      );
      navigate("/viewreward", { replace: true });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);

      if (err?.response?.data?.response?.message) {
        if (
          err.response?.status === 404 &&
          err?.response?.data?.response?.redirect === "cert-details"
        ) {
          secureLocalStorage.setItem("certDetail", true);
          const detail_data = err?.response?.data?.response;
          secureLocalStorage.setItem(
            "cert_details",
            JSON.stringify({
              fname: detail_data.fname,
              lname: detail_data.lname,
              address: detail_data.address,
              address2: detail_data.address2,
              city: detail_data.city,
              state: detail_data.state,
              color: detail_data.color,
              zip: detail_data.zip,
              phone: detail_data.phone,
              email: detail_data.email,
              cardImage: detail_data.image,
              cardName: detail_data.product,
            })
          );
          navigate("/cert-details");
        } else {
          setFieldError("voucher", err?.response?.data?.response?.message);
        }
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,

    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      voucher: "",
      check: false,
      captchaValidate: true,
    },
    validationSchema: validateVoucher,
    onSubmit: (values) => {
      voucherEnter(values);
    },
  });

  useEffect(() => {
    if (certificate) {
      navigate("/viewreward", { replace: true });
      setTimeout(() => {
        setShow(true);
      }, 500);
    } else {
      setShow(true);
      secureLocalStorage.removeItem("cert_details");
      secureLocalStorage.removeItem("certDetail");
      secureLocalStorage.removeItem("redeemDetail");
      secureLocalStorage.removeItem("redeemed_info");
    }
  }, []);

  return show ? (
    <div className="make-center">
      <div className="container home-new">
        <div className="logo-sec">
          <img src={Logo} alt="logo" className="img-fluid home-logo" />
          <h4 className="read-head">Thank You for Your Purchase!</h4>
          <ul className="instruction-list">
            <li>
              <img src={binoculars} alt="img" />
              <span>Enter your Certificate Number you received via email.</span>
            </li>
            <li>
              <img src={mouse} alt="img" />
              <span>Select your gift(s).</span>
            </li>
            <li>
              <img src={spam} alt="img" />
              <span> Put in your complete shipping information </span>
            </li>
            <li>
              <img src={shipping} alt="img" />
              <span>
                Click order and you will receive an email confirmation
              </span>
            </li>
          </ul>
        </div>
        <div className="form-sec">
          <div className="white-sec">
            <img src={question} alt="img" />
            <span>
              Your recent purchase qualifies you for a gift with purchase.
            </span>
          </div>

          <form onSubmit={handleSubmit} noValidate className="voucher-entery">
            <label htmlFor="voucher">CERTIFICATE NUMBER</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder=""
                aria-label=""
                aria-describedby="basic-addon1"
                name="voucher"
                value={values.voucher || ""}
                onChange={handleChange}
                id="voucher"
                maxLength={11}
              />
              <div className="input-group-prepend">
                <button className="btn" type="submit">
                  Go
                </button>
              </div>
            </div>
            {errors.voucher && touched.voucher ? (
              <span className="error-span">{errors.voucher}</span>
            ) : errors.check && touched.check ? (
              <span className="error-span">{errors.check}</span>
            ) : errors.captchaValidate && touched.captchaValidate ? (
              <span className="error-span">{errors.captchaValidate}</span>
            ) : null}

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={values.check}
                name="check"
                onChange={handleChange}
                id="terms"
              />
              <label className="form-check-label" htmlFor="terms">
                YES, I HAVE READ THE ABOVE INFORMATION
              </label>
            </div>
            <div className="g-recaptcha">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                onChange={onCaptchaChange}
              />
            </div>
          </form>
        </div>
        <div className="clearfix"></div>
        <p className="copyright-text">
          © Copyright {new Date().getFullYear()}. All rights reserved.
        </p>
      </div>
      {loading && (
        <ReactLoading
          type="spinningBubbles"
          color="#185791"
          className="bar-loader"
        />
      )}
    </div>
  ) : (
    <div className="white-screen"></div>
  );
}
