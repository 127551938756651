import { useFormik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AdminListService from "../../../services/admin-list.service";
import "./login.css";
import Logo from "../../../Images/logo.webp";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux/index";
import { Helmet } from "react-helmet";
import { signImSchema } from "../../../schema";
import ReactLoading from "react-loading";
import $ from "jquery";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const loginCheck = async (values) => {
    setLoading(true);
    try {
      const response = await AdminListService.loginAdmin(values);

      await userActions.logIn(
        {
          adminAccessToken: response.data.access_token,
          admin_id: response.data.user.id,
          user_type: response.data.user.user_type,
        },
        "adminLogin"
      );
      navigate("/admin/dashboard", { replace: true });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);

      if (err?.response?.status === 401) {
        toast.error("Username or Password is invalid!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.error(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: signImSchema,
    onSubmit: (values, action) => {
      loginCheck(values);
    },
  });
  const [pass, setPass] = useState(false);

  return (
    <div className="login-form">
      <Helmet>
        <title>Admin Panel</title>
      </Helmet>
      <div className="container-login100">
        <div className="wrap-login100">
          <img src={Logo} alt="" className="login-logo" />
          {/* <span className="login100-form-title"> Account Login </span> */}
          <form
            className="login100-form validate-form"
            onSubmit={handleSubmit}
            noValidate
          >
            <div
              className={`wrap-input100 validate-input ${
                errors.username && touched.username ? "alert-validate" : ""
              } `}
              data-validate="Enter Username"
              id="Username"
            >
              <input
                className={`input100 ${
                  values.username !== "" ? "has-val" : ""
                }`}
                type="text"
                onChange={handleChange}
                onBlur={(e) => {
                  $("#Username").removeClass("input-focus");
                }}
                onFocus={() => {
                  $("#Username").addClass("input-focus");
                }}
                name="username"
                value={values.username || ""}
                placeholder="Username"
              />
              <span
                className="focus-input100"
                data-placeholder="&#xf007;"
              ></span>
            </div>

            <div
              className={`wrap-input100 validate-input ${
                errors.password && touched.password ? "alert-validate" : ""
              } `}
              data-validate="Enter Password"
              id="password"
            >
              <div
                className="eye-icon-pass"
                onClick={() => {
                  setPass(!pass);
                }}
              >
                {pass ? (
                  <i class="bi bi-eye-slash"></i>
                ) : (
                  <i className="bi bi-eye"></i>
                )}
              </div>
              <input
                className={`input100 ${
                  values.password !== "" ? "has-val" : ""
                }`}
                type={`${pass ? "text" : "password"}`}
                onChange={handleChange}
                onBlur={(e) => {
                  $("#password").removeClass("input-focus");
                }}
                onFocus={() => {
                  $("#password").addClass("input-focus");
                }}
                name="password"
                value={values.password || ""}
                placeholder="Password"
              />
              <span
                className="focus-input100"
                data-placeholder="&#xf023;"
              ></span>
              <Link to="/admin/forgot-pass">Forgot Password</Link>
            </div>

            <div className="container-login100-form-btn">
              <button className="login100-form-btn" type="submit">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
      {loading && (
        <ReactLoading
          type="spinningBubbles"
          color="#185791"
          className="bar-loader"
        />
      )}
    </div>
  );
}
