import React from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";

export default function ProductLoader({
  cardImg,
  has_variants = false,
  height = "245px",
}) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {isLoading ? <Skeleton count={1} height={height} /> : null}

      <img
        className="img-fluid"
        src={
          !has_variants
            ? `${process.env.REACT_APP_IMAGE_Link}${cardImg}`
            : cardImg
        }
        alt="product"
        style={isLoading ? { display: "none" } : {}}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
    </>
  );
}
