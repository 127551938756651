import React, { useEffect, useState } from "react";
import Sidebar from "../includes/Sidebar";
import MainHeading from "../includes/MainHeading";
import Footer from "../includes/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminListService from "../../../services/admin-list.service";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import ReactLoading from "react-loading";
import { updateVariant } from "../../../schema";
import CreatableSelect from "react-select/creatable";

export default function EditVariant() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const colorData = [
    { value: "Black", label: "Black" },
    { value: "Charcoal", label: "Charcoal" },
    { value: "Dark Blue", label: "Dark Blue" },
    { value: "Dark Green", label: "Dark Green" },
    { value: "Gray", label: "Gray" },
    { value: "Light Blue", label: "Light Blue" },
    { value: "Light Green", label: "Light Green" },
    { value: "Orange", label: "Orange" },
    { value: "Pink", label: "Pink" },
    { value: "Red", label: "Red" },
    { value: "Tan", label: "Tan" },
    { value: "Yellow", label: "Yellow" },
    { value: "White", label: "White" },
  ];

  const sizeData = [
    { value: "Small", label: "Small" },
    { value: "Medium", label: "Medium" },
    { value: "Large", label: "Large" },
    { value: "Extra Large", label: "Extra Large" },
    { value: "Double X", label: "Double X" },
    { value: "One size fits all", label: "One size fits all" },
  ];

  useEffect(() => {
    if (adminAccessToken) {
      const getSingleGift = async () => {
        setLoading(true);
        try {
          const response = await AdminListService.getGiftVariationById(id);

          setValues({
            title: response.data.variant.product.name,
            description: response.data.variant.product.description,
            image: response.data.variant.image,
            points: response.data.variant.product.points,

            color: response.data.variant.color,

            gender: response.data.variant.gender,
            material: response.data.variant.material,
            function: response.data.variant.function,
            size: response.data.variant.size,
          });
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);

          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }
      };
      getSingleGift();
    } else {
      navigate("/admin/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const handleFileChange = async (e) => {
    setFile(e.target.files[0]);
  };

  const updateGiftFun = async (values) => {
    const formData = new FormData();
    if (file !== "") {
      formData.append("image", file);
    }

    formData.append("adminId", parseInt(admin_id));

    formData.append("points", values.points);
    formData.append("name", values.title);
    formData.append("description", values.description);
    formData.append("color", values.color);
    formData.append("size", values.size);
    formData.append("gender", values.gender);
    formData.append("material", values.material);
    formData.append("function", values.function);

    setLoading(true);
    try {
      const response = await AdminListService.updateGiftVariantById(
        formData,
        id
      );

      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      setTimeout(() => {
        navigate("/admin/view-variations", { replace: true });
      }, 1000);
    } catch (err) {
      console.log(err);
      setLoading(false);

      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.error(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.data?.image?.length) {
        toast.error(err?.response?.data?.image[0], {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.data?.model_no?.length) {
        toast.error(err?.response?.data?.model_no[0], {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.data?.item_no?.length) {
        toast.error(err?.response?.data?.item_no[0], {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.data?.name?.length) {
        toast.error(err?.response?.data?.name[0], {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.data?.description?.length) {
        toast.error(err?.response?.data?.description[0], {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      title: "",
      description: "",
      image: "",

      color: "",
      gender: "",
      size: "",
      function: "",
      material: "",
      points: "",
    },
    validationSchema: updateVariant,
    onSubmit: (values) => {
      updateGiftFun(values);
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Product Variation" />

          <div className="mt-4 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Update Variation </h4>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          Product Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product Name"
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("description", e.target.value);
                          }}
                          name="title"
                          value={values.title || ""}
                        />
                        {errors.title && touched.title ? (
                          <span className="text-danger">{errors.title}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          Points <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Points"
                          onChange={handleChange}
                          name="points"
                          value={values.points || ""}
                        />
                        {errors.points && touched.points ? (
                          <span className="text-danger">{errors.points}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-field-box">
                        <label>
                          Description <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Description"
                          onChange={handleChange}
                          name="description"
                          value={values.description || ""}
                        />
                        {errors.description && touched.description ? (
                          <span className="text-danger">
                            {errors.description}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input-field-box">
                        <label>
                          Color <span className="text-danger">*</span>
                        </label>
                        {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Color"
                          onChange={handleChange}
                          name="color"
                          value={values.color || ""}
                        /> */}
                        <CreatableSelect
                          classNamePrefix="react-select"
                          className={`select_1 dropdown`}
                          placeholder={
                            values.color !== "" ? values.color : "Select Color"
                          }
                          options={colorData}
                          isSearchable={true}
                          isClearable={true}
                          name="color"
                          onChange={(selectedOption) => {
                            if (selectedOption) {
                              setFieldValue("color", selectedOption.value);
                            } else {
                              setFieldValue("color", "");
                            }
                          }}
                        />
                        {errors.color && touched.color ? (
                          <span className="text-danger">{errors.color}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input-field-box">
                        <label>
                          Size <span className="text-danger">*</span>
                        </label>

                        {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Size"
                          onChange={handleChange}
                          name="size"
                          value={values.size || ""}
                        /> */}
                        <CreatableSelect
                          classNamePrefix="react-select"
                          className={`select_1 dropdown`}
                          placeholder={
                            values.size !== "" ? values.size : "Select Size"
                          }
                          options={sizeData}
                          isSearchable={true}
                          isClearable={true}
                          name="size"
                          onChange={(selectedOption) => {
                            if (selectedOption) {
                              setFieldValue("size", selectedOption.value);
                            } else {
                              setFieldValue("size", "");
                            }
                          }}
                        />
                        {errors.size && touched.size ? (
                          <span className="text-danger">{errors.size}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input-field-box">
                        <label>Gender</label>

                        <select
                          className="form-select"
                          name="gender"
                          value={values.gender || ""}
                          onChange={handleChange}
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Unisex">Unisex</option>
                        </select>
                        {errors.gender && touched.gender ? (
                          <span className="text-danger">{errors.gender}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input-field-box">
                        <label>Material</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Material"
                          onChange={handleChange}
                          name="material"
                          value={values.material || ""}
                        />
                        {errors.material && touched.material ? (
                          <span className="text-danger">{errors.material}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input-field-box">
                        <label>Function</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Function"
                          onChange={handleChange}
                          name="function"
                          value={values.function || ""}
                        />
                        {errors.function && touched.function ? (
                          <span className="text-danger">{errors.function}</span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-10">
                      <div className=" file-area">
                        <input
                          type="file"
                          name="images"
                          id="images"
                          required="required"
                          accept=".png,.jpg,.jpeg,.webp"
                          onChange={handleFileChange}
                        />
                        <div className="file-dummy">
                          <div className="success">
                            Great, your image is selected. Keep on.
                          </div>
                          <div className="default">Choose Image</div>
                        </div>
                      </div>
                      {errors.image && touched.image ? (
                        <span className="text-danger">{errors.image}</span>
                      ) : null}
                    </div>
                    <div className="col-md-2 text-center">
                      <img
                        src={values?.image || ""}
                        alt=""
                        className="img-fluid edit-gift-img"
                      />
                    </div>

                    <div className="col-12">
                      <button className="btn btn-primary mt-3" type="submit">
                        Update Product
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {loading && (
        <ReactLoading
          type="spinningBubbles"
          color="#185791"
          className="bar-loader"
        />
      )}
    </div>
  );
}
