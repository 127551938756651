import React, { useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap-extended/lib/bootstrap-extended.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-loading-skeleton/dist/skeleton.css";
import "./App.css";
import $ from "jquery";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Login from "./Components/admin/login/Login";

import { useDispatch, useSelector } from "react-redux";
import NotFound from "./Components/admin/includes/NotFound";

import { bindActionCreators } from "redux";
import { actionCreaters } from "./Redux";

import { ToastContainer, toast } from "react-toastify";

import axios from "axios";
import Error429 from "./Components/admin/includes/Error429";
import Dashboard from "./Components/admin/Dashboard/Dashboard";
import jwtDecode from "jwt-decode";
import ForgotPass from "./Components/admin/login/ForgotPass";

import Profile from "./Components/admin/Dashboard/Profile";

import ViewGifts from "./Components/admin/ManageGifts/ViewGifts";

import ImportCsv from "./Components/admin/ManageDocument/ImportCsv";
import EditGift from "./Components/admin/ManageGifts/EditGift";
import AddGift from "./Components/admin/ManageGifts/AddGift";
import ViewOrders from "./Components/admin/ManageOrders/ViewOrders";
import ViewArchiveOrders from "./Components/admin/ManageOrders/ViewArchiveOrders";
import ViewUsedVouchers from "./Components/admin/Vouchers/ViewUsedVouchers";
import ViewVouchers from "./Components/admin/Vouchers/ViewVouchers";
import EditVoucher from "./Components/admin/Vouchers/EditVoucher";
import ViewEmails from "./Components/admin/ManageEmails/ViewEmails";
import EditEmail from "./Components/admin/ManageEmails/EditEmail";
import AddEmail from "./Components/admin/ManageEmails/AddEmail";
import ViewArchiveVouchers from "./Components/admin/Vouchers/ViewArchiveVouchers";

import ManageJobs from "./Components/admin/ManageJobs/ManageJobs";
import JobDetail from "./Components/admin/ManageJobs/JobDetail";

import { useLayoutEffect } from "react";

import NoInternet from "./Components/user/NoInternet";
import PavlinaIndex from "./Components/admin/Pavlina/PavlinaIndex";
import Home from "./Components/user/Home";
import ProductsPage from "./Components/user/ProductsPage";
import Header from "./Components/admin/includes/Header";
import CartPage from "./Components/user/CartPage";
import UserOrderShipping from "./Components/user/UserOrderShipping";
import ContactUs from "./Components/user/ContactUs";
import ProductDetail from "./Components/user/ProductDetail";
import CertiDetail from "./Components/user/CertiDetail";
import Help from "./Components/user/Help";
import TOS from "./Components/user/TOS";
import ManageNewsletter from "./Components/admin/ManageWebsite/ManageNewsletter";
import ManageWebsite from "./Components/admin/ManageWebsite/ManageWebsite";
import NewImportCsv from "./Components/admin/ManageDocument/NewImportCsv";
import NewImportVouchers from "./Components/admin/ManageDocument/NewImportVouchers";
import VerifyVoucher from "./Components/user/VerifyVoucher";
import ThankYou from "./Components/user/ThankYou";
import ManualOrders from "./Components/admin/ManageDocument/ManualOrders";
import StanleyDeletedProducts from "./Components/admin/ManageGifts/StanleyDeletedProducts";
import EditJob from "./Components/admin/ManageJobs/EditJob";
import ImportProducts from "./Components/admin/ImportProducts/ImportProducts";
import ViewProducts from "./Components/admin/ImportProducts/ViewProducts";
import EditVariant from "./Components/admin/ImportProducts/EditVariant";
import AddVariant from "./Components/admin/ImportProducts/AddVariant";

function App() {
  const navigate = useNavigate();
  const locat = useLocation();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  useEffect(() => {
    if (locat.pathname.match(/\/{2,}/)) {
      if (locat.pathname.indexOf("admin/") > -1) {
        window.location.href = "/admin/login";
      } else {
        window.location.href = "/";
      }
    }

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 429) {
          window.location.href = "/error-429";
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      locat.pathname !== "/" &&
      locat.pathname !== "/viewreward" &&
      locat.pathname !== "/contact-us" &&
      locat.pathname !== "/cart" &&
      locat.pathname !== "/help" &&
      locat.pathname !== "/TOS" &&
      locat.pathname !== "/cert-details" &&
      locat.pathname !== "/thank-you" &&
      locat.pathname.indexOf("order-detail") !== 1 &&
      locat.pathname.indexOf("verify-voucher") !== 1 &&
      locat.pathname.indexOf("product-detail") !== 1
    ) {
      if (adminAccessToken) {
        const backToTop = () => {
          return (
            $("html, body").animate(
              {
                scrollTop: 0,
              },
              600
            ),
            !1
          );
        };
        backToTop();
        const decoded = jwtDecode(adminAccessToken);
        const currentTime = Math.floor(new Date().getTime() / 1000);

        if (currentTime > decoded.exp) {
          toast.error("Request time out!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          userActions.logOut("adminLogOut");
          navigate("/admin/login", { replace: true });
        } else if (
          locat.pathname === "/admin" ||
          locat.pathname === "/admin/" ||
          locat.pathname === "/login" ||
          locat.pathname === "/login/" ||
          locat.pathname === "/admin/login" ||
          locat.pathname === "/admin/login/"
        ) {
          navigate("/admin/dashboard", { replace: true });
        }
      } else {
        if (
          locat.pathname.indexOf("admin") > -1 &&
          locat.pathname !== "/admin/forgot-pass"
        ) {
          navigate("/admin/login", { replace: true });
        }
      }
    }
    // eslint-disable-next-line
  }, [locat.pathname]);

  useLayoutEffect(() => {
    if (
      locat.pathname === "/" ||
      locat.pathname === "/viewreward" ||
      locat.pathname === "/cart" ||
      locat.pathname === "/help" ||
      locat.pathname === "/TOS" ||
      locat.pathname === "/contact-us" ||
      locat.pathname === "/thank-you" ||
      locat.pathname.indexOf("order-detail") === 1 ||
      locat.pathname.indexOf("verify-voucher") === 1 ||
      locat.pathname.indexOf("product-detail") === 1
    ) {
      $("html").attr("class", "img-bg");
    }
  }, [locat.pathname]);

  return (
    <NoInternet>
      <ToastContainer limit={1} />
      {locat.pathname !== "/admin/login" &&
      locat.pathname !== "/admin/" &&
      locat.pathname !== "/admin" &&
      locat.pathname !== "/login/" &&
      locat.pathname !== "/login" &&
      locat.pathname !== "/admin/forgot-pass" &&
      locat.pathname !== "/admin/forgot-pass/" &&
      locat.pathname !== "/" &&
      locat.pathname !== "/cart" &&
      locat.pathname !== "/viewreward" &&
      locat.pathname !== "/contact-us" &&
      locat.pathname !== "/cert-details" &&
      locat.pathname !== "/help" &&
      locat.pathname !== "/TOS" &&
      locat.pathname !== "/thank-you" &&
      locat.pathname.indexOf("verify-voucher") !== 1 &&
      locat.pathname.indexOf("order-detail") !== 1 &&
      locat.pathname.indexOf("product-detail") !== 1 ? (
        <Header />
      ) : null}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/verify-voucher/:vId/:pId" element={<VerifyVoucher />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/viewreward" element={<ProductsPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/help" element={<Help />} />
        <Route path="/TOS" element={<TOS />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/cert-details" element={<CertiDetail />} />
        <Route path="/product-detail/:id" element={<ProductDetail />} />
        <Route
          path="/product-detail/:id/:variant"
          element={<ProductDetail />}
        />
        <Route path="/order-detail/:certi" element={<UserOrderShipping />} />
        <Route path="/admin" element={<Login />} />
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/forgot-pass" element={<ForgotPass />} />
        <Route
          path="/admin/dashboard"
          element={adminAccessToken ? <Dashboard /> : <Login />}
        />
        <Route
          path="/admin/profile"
          element={adminAccessToken ? <Profile /> : <Login />}
        />
        <Route
          path="/admin/manage-website"
          element={adminAccessToken ? <ManageWebsite /> : <Login />}
        />
        <Route
          path="/admin/manage-newsletter"
          element={adminAccessToken ? <ManageNewsletter /> : <Login />}
        />
        <Route
          path="/admin/import-file"
          element={adminAccessToken ? <NewImportCsv /> : <Login />}
        />
        <Route
          path="/admin/oos-emails"
          element={adminAccessToken ? <NewImportVouchers /> : <Login />}
        />
        <Route
          path="/admin/manual-orders"
          element={adminAccessToken ? <ManualOrders /> : <Login />}
        />
        <Route
          path="/admin/import-file-new"
          element={adminAccessToken ? <ImportCsv /> : <Login />}
        />
        <Route
          path="/admin/manage-products"
          element={adminAccessToken ? <ViewGifts /> : <Login />}
        />
        <Route
          path="/admin/add-products"
          element={adminAccessToken ? <AddGift /> : <Login />}
        />
        <Route
          path="/admin/edit-product/:id"
          element={adminAccessToken ? <EditGift /> : <Login />}
        />
        {/* <Route
          path="/admin/stanley-deactivated-products"
          element={adminAccessToken ? <StanleyDeletedProducts /> : <Login />}
        /> */}

        {/* <Route
          path="/admin/orders"
          element={adminAccessToken ? <ViewOrders /> : <Login />}
        /> */}
        <Route
          path="/admin/orders"
          element={adminAccessToken ? <PavlinaIndex /> : <Login />}
        />

        <Route
          path="/admin/archOrder"
          element={adminAccessToken ? <ViewArchiveOrders /> : <Login />}
        />
        <Route
          path="/admin/vouchers"
          element={adminAccessToken ? <ViewVouchers /> : <Login />}
        />
        <Route
          path="/admin/used-vouchers"
          element={adminAccessToken ? <ViewUsedVouchers /> : <Login />}
        />
        <Route
          path="/admin/edit-voucher/:id"
          element={adminAccessToken ? <EditVoucher /> : <Login />}
        />
        <Route
          path="/admin/archive-vouchers"
          element={adminAccessToken ? <ViewArchiveVouchers /> : <Login />}
        />
        <Route
          path="/admin/manage-email"
          element={adminAccessToken ? <ViewEmails /> : <Login />}
        />
        <Route
          path="/admin/add-email"
          element={adminAccessToken ? <AddEmail /> : <Login />}
        />
        <Route
          path="/admin/edit-email/:id"
          element={adminAccessToken ? <EditEmail /> : <Login />}
        />
        <Route
          path="/admin/manage-job"
          element={adminAccessToken ? <ManageJobs /> : <Login />}
        />
        <Route
          path="/admin/job-detail/:job"
          element={adminAccessToken ? <JobDetail /> : <Login />}
        />
        <Route
          path="/admin/edit-job/:job"
          element={adminAccessToken ? <EditJob /> : <Login />}
        />
        <Route
          path="/admin/add-variation"
          element={adminAccessToken ? <AddVariant /> : <Login />}
        />
        <Route
          path="/admin/upload-variations"
          element={adminAccessToken ? <ImportProducts /> : <Login />}
        />
        <Route
          path="/admin/edit-variation/:id"
          element={adminAccessToken ? <EditVariant /> : <Login />}
        />
        <Route
          path="/admin/view-variations"
          element={adminAccessToken ? <ViewProducts /> : <Login />}
        />

        {/* <Route
          path="/admin/pavlina-index"
          element={adminAccessToken ? <PavlinaIndex /> : <Login />}
        /> */}
        <Route path="/error-429" element={<Error429 />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {locat.pathname.match(/\/{2,}/) ? (
        <div className="white-screen"></div>
      ) : null}
    </NoInternet>
  );
}

export default App;
