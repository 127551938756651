import React, { useEffect, useState } from "react";
import MainHeading from "../includes/MainHeading";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminListService from "../../../services/admin-list.service";

import { useFormik } from "formik";
import { importDocument } from "../../../schema";
import { toast } from "react-toastify";

import ReactLoading from "react-loading";
import Skeleton from "react-loading-skeleton";
import ImageLoader from "../includes/ImageLoader";
import $ from "jquery";

export default function NewImportVouchers() {
  const [formSubmiting, setFormSubmiting] = useState(false);
  const [tableLoader, settableLoader] = useState(false);
  const [giftError, setGiftError] = useState(false);
  const [tableData, settableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [duplicate, setDuplicate] = useState([]);
  const [missed, setMissed] = useState([]);
  const [errorData, setErrorData] = useState([]);
  const [productShow, setProductShow] = useState("");

  const [allSelected, setAllSelected] = useState(true);

  const [selectedPr, setSelectedPr] = useState({});

  const [file, setFile] = useState("");

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();

  const giftsLists = async () => {
    setSelectedPr([]);
    settableData([]);
    setFilteredData([]);
    setAllSelected(true);

    try {
      setProductShow("ok");
      settableLoader(true);

      const responce = await AdminListService.getGiftsStanly();

      const res = responce?.data?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          CardName: value.name,
          CardImage: value.image,
          description: value.description,
          has_variants: value.has_variants,

          CardId: value.id,
        });
      });

      settableData([...results]);
      setFilteredData([...results]);
      settableLoader(false);
      setProductShow("ok");
    } catch (err) {
      console.log(err);
      settableData([]);
      setFilteredData([]);
      settableLoader(false);
      setProductShow("fail");
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      giftsLists();
    } else {
      navigate("/admin/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const handleFileChange = async (e) => {
    await setFile(e.target.files[0]);
    await setFieldValue("file", e.target.value);
  };

  const uploadSheet = async (values) => {
    var selectedCards = selectedPr
      .map((value) => {
        return value.CardId;
      })
      .join(",");

    if (!selectedCards.length) {
      setGiftError(true);
    } else {
      setGiftError(false);
      setFormSubmiting(true);
      const formData = new FormData();
      if (file !== "") {
        formData.append("file", file);
      }

      formData.append("adminId", parseInt(admin_id));
      formData.append("products", selectedCards);

      try {
        const response = await AdminListService.uploadVouchersSheetNew(
          formData
        );

        toast.success(response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setDuplicate([]);
        setMissed([]);
        setFormSubmiting(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        // navigate("/admin/vouchers");
      } catch (err) {
        console.log(err);
        setFormSubmiting(false);
        if (err?.response?.data?.failure?.length === 0) {
          if (err?.response?.data?.missed?.miss?.length > 0) {
            setErrorData([]);
            setMissed(err?.response?.data?.missed?.miss);
            console.log("missed");
          }
          if (err?.response?.data?.duplicate?.dup?.length > 0) {
            setErrorData([]);
            setDuplicate(err?.response?.data?.duplicate?.dup);
          }
        } else {
          if (Array.isArray(err?.response?.data?.failure)) {
            const errors = [];

            err?.response?.data?.failure.map((value) => {
              return errors.push({
                row: value.row,
                errors: value.errors[0].toString().replaceAll("_", " "),
              });
            });

            setErrorData([...errors]);
            setDuplicate([]);
            setMissed([]);
          }
        }
        if (
          err?.response?.data?.response &&
          typeof err?.response?.data?.response === "string"
        ) {
          toast.error(err?.response?.data?.response, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (err?.response?.data?.file?.length) {
          toast.error(err?.response?.data?.file[0], {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("Something went wrong, try again", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: { file: "" },
    validationSchema: importDocument,
    onSubmit: async () => {
      if (!selectedPr.length) {
        setGiftError(true);
      } else {
        await setGiftError(false);
        document.querySelector("#product-view").click();
      }
    },
  });

  const allSelect = async () => {
    $(".gift-scroll input:checkbox").prop("checked", allSelected);
    const selectedIdz = selectedPr.map((item) => item.CardId);
    const productsToAdd = filteredData.filter(
      (product) => !selectedIdz.includes(product.CardId)
    );

    const productsAlreadyAdd = filteredData.filter((product) =>
      selectedIdz.includes(product.CardId)
    );
    if (allSelected) {
      setSelectedPr([...selectedPr, ...productsToAdd]);

      if (
        productsToAdd.length === filteredData.length ||
        selectedIdz.length === filteredData.length ||
        selectedIdz.length + productsToAdd.length === filteredData.length ||
        productsAlreadyAdd.length + productsToAdd.length === filteredData.length
      ) {
        setAllSelected(false);
      }
    } else {
      const searchedProductIds = filteredData.map((product) => product.CardId);

      const newFilter = selectedPr.filter(
        (item) => !searchedProductIds.includes(item.CardId)
      );
      setSelectedPr(newFilter);

      setAllSelected(true);
    }
  };

  const onCheckChange = (id) => {
    const index = selectedPr.findIndex((item) => item.CardId === id);
    if (index === -1) {
      const recordToAdd = filteredData.find((item) => item.CardId === id);
      if (recordToAdd) {
        setSelectedPr([...selectedPr, recordToAdd]);
      }
    } else {
      const newFilter = [...selectedPr];
      newFilter.splice(index, 1);
      setSelectedPr(newFilter);
    }
  };

  const handleSearch = (e) => {
    setProductShow("ok");
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = tableData.filter((item) => {
        // Customize this condition based on your data structure and search requirements
        return (
          item.description.toString().toLowerCase().includes(searchQuery) ||
          item.CardName.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
      if (newFilteredData.length) {
        setProductShow("ok");
        const searchedProductIds = newFilteredData.map(
          (product) => product.CardId
        );
        const newFilter = selectedPr.filter((item) =>
          searchedProductIds.includes(item.CardId)
        );
        if (newFilteredData.length === newFilter.length) {
          setAllSelected(false);
        } else {
          setAllSelected(true);
        }
      } else {
        setProductShow("fail");
      }
    } else {
      setFilteredData(tableData);
      setAllSelected(true);
    }
  };

  useEffect(() => {
    if (selectedPr.length) {
      const saveSelected = selectedPr
        .filter((item) => item.CardId)
        .map((item) => item.CardId);

      setCardData(saveSelected);
    } else {
      setCardData([]);
    }
  }, [selectedPr]);

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Import File" />
          <div className="row">
            <div className="mt-4 col-xl-12 col-lg-12 col-md-12 col-12">
              <div
                className={`card dashboard-cards ${
                  errors.file && touched.file ? "error-card" : ""
                }`}
              >
                {" "}
                {errors.file && touched.file ? (
                  <span className="text-danger">{errors.file}</span>
                ) : null}
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <h4 className="mb-0 col-lg-8">
                      You can import an XLSX file that contains used vouchers.
                    </h4>

                    <div className="col-lg-4 text-end">
                      <a
                        href="/assets/csv/OOS_Email_sheet_sample.xlsx"
                        download={true}
                        className="btn btn-primary"
                      >
                        DOWNLOAD SAMPLE SHEET
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {errorData.length ? (
                    <div className="row">
                      {errorData.map((res, index) => {
                        return (
                          <div className="col-lg-6">
                            <div className="alert alert-danger " key={index}>
                              <b>Note!</b> Problem in row number {res.row} ,
                              <b> Error! </b>
                              {res.errors}
                            </div>{" "}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}

                  <div className=" file-area">
                    <input
                      type="file"
                      name="images"
                      id="images"
                      required="required"
                      accept=".xls, .xlsx, .csv"
                      onChange={handleFileChange}
                      onClick={(e) => {
                        e.target.value = null;
                        setFile("");
                        setFieldValue("file", "");
                      }}
                    />
                    <div className="file-dummy">
                      <div className="success">
                        Great, your files are selected. Keep on.
                      </div>
                      <div className="default">Import XLSX File</div>
                    </div>
                  </div>

                  <div className="row">
                    {duplicate.length ? (
                      <div className="col-md-6 mt-3">
                        <table className="text-nowrap  table caption-top">
                          <caption>
                            <strong className="font-17">
                              These Vouchers haven't been uploaded due to
                              duplication.
                            </strong>
                          </caption>
                          <thead className="table-light">
                            <tr>
                              <th scope="col">Sr#</th>

                              <th scope="col">Certificate</th>
                            </tr>
                          </thead>
                          <tbody>
                            {duplicate.map((el, index) => (
                              <tr key={index}>
                                <td className="align-middle">{index + 1}</td>
                                <td className="align-middle">
                                  <span className="text-control">{el}</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                    {missed.length ? (
                      <div className="col-md-6 mt-3">
                        <table className="text-nowrap  table caption-top">
                          <caption>
                            <strong className="font-17">
                              These Vouchers haven't been uploaded due to their
                              Active Status.
                            </strong>
                          </caption>
                          <thead className="table-light">
                            <tr>
                              <th scope="col">Sr#</th>

                              <th scope="col">Certificate</th>
                            </tr>
                          </thead>
                          <tbody>
                            {missed.map((el, index) => (
                              <tr key={index}>
                                <td className="align-middle">{index + 1}</td>
                                <td className="align-middle">
                                  <span className="text-control">{el}</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* <div className="card-footer">
                  <div className="col-12 text-center">
                    <button className="btn btn-primary my-2 " type="submit">
                      Submit
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
            {selectedPr.length ? (
              <div className="mt-4  col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="card dashboard-cards">
                  <div className="bg-white  py-3 card-header">
                    <h4 className="mb-0">Selected Products</h4>
                  </div>
                  <div className="gift-scroll gift-lists">
                    <div className="card-body on-off-cards">
                      <div className="row gy-4">
                        {selectedPr.map((el) => (
                          <div
                            className="col-12 col-md-4 col-lg-2 text-center"
                            key={el.CardId}
                          >
                            <div className="form-check form-switch">
                              <label className="form-check-label form-check-label-with-text mb-0  ">
                                <span className="card-name-text">
                                  {el.CardName}
                                </span>
                                {el.has_variants ? (
                                  <img
                                    src={el.CardImage}
                                    className="card_logo_img"
                                    alt="card pic"
                                  />
                                ) : (
                                  <ImageLoader cardImg={el.CardImage} />
                                )}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="mt-4  col-xl-12 col-lg-12 col-md-12 col-12">
              <div
                className={`card dashboard-cards ${
                  giftError ? "error-card" : ""
                }`}
              >
                {giftError ? (
                  <span className="text-danger">Please select a product</span>
                ) : null}
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <div className="col-lg-5 d-flex align-items-center">
                      <h4 className="mb-0">Select Products</h4>
                    </div>
                    <div className="col-lg-7 text-end">
                      <button
                        className="btn btn-primary m-0"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                {tableData.length ? (
                  <div className="card-body ">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-field-box mb-lg-0">
                          <label>Search product by name and description </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                handleSearch(e);
                              }}
                            />
                            {filteredData.length ? (
                              <button
                                className="btn btn-success  "
                                type="button"
                                onClick={allSelect}
                              >
                                {allSelected ? "Select " : "Unselect "}
                                All
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="gift-scroll gift-lists">
                  <div className="card-body on-off-cards">
                    {productShow === "ok" ? (
                      tableLoader ? (
                        <Skeleton
                          count={20}
                          height="180px"
                          width="18.9%"
                          inline={true}
                          className="m-2"
                        />
                      ) : (
                        <div className="row">
                          {filteredData.length
                            ? filteredData.map((el) => (
                                <div
                                  className="col-12 col-md-4 col-lg-2 text-center"
                                  key={el.CardId}
                                >
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`gift${el.CardId}`}
                                      value={el.CardId}
                                      onChange={() => {
                                        onCheckChange(el.CardId);
                                      }}
                                      defaultChecked={
                                        cardData?.includes(el.CardId)
                                          ? true
                                          : false
                                      }
                                    />
                                    <label
                                      className="form-check-label form-check-label-with-text mt-2"
                                      htmlFor={`gift${el.CardId}`}
                                    >
                                      <span className="card-name-text">
                                        {el.CardName}
                                      </span>
                                      {el.has_variants ? (
                                        <img
                                          src={el.CardImage}
                                          className="card_logo_img"
                                          alt="card pic"
                                        />
                                      ) : (
                                        <ImageLoader cardImg={el.CardImage} />
                                      )}
                                    </label>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      )
                    ) : productShow === "fail" ? (
                      <h4 className="mb-0 text-danger">No Product Found</h4>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <button
              type="button"
              className="d-none"
              id="product-view"
              data-bs-toggle="modal"
              tabIndex="-3"
              data-bs-target="#staticBackdrop"
            ></button>

            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable product-scroll">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="staticBackdropLabel">
                      Selected Products
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="card-body on-off-cards">
                      <div className="row">
                        {selectedPr.length
                          ? selectedPr.map((el) => (
                              <div
                                className="col-12 col-md-4 col-lg-2 text-center"
                                key={el.CardId}
                              >
                                <div className="form-check form-switch">
                                  <label className="form-check-label form-check-label-with-text ">
                                    <span className="card-name-text">
                                      {el.CardName}
                                    </span>
                                    {el.has_variants ? (
                                      <img
                                        src={el.CardImage}
                                        className="card_logo_img"
                                        alt="card pic"
                                      />
                                    ) : (
                                      <ImageLoader cardImg={el.CardImage} />
                                    )}
                                  </label>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    {selectedPr.length ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          uploadSheet(values);
                        }}
                      >
                        Submit
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {formSubmiting && (
        <ReactLoading
          type="spinningBubbles"
          color="#185791"
          className="bar-loader"
        />
      )}
    </div>
  );
}
