import React, { useLayoutEffect, useState } from "react";

import HeaderUser from "./Includes/HeaderUser";
import FooterUser from "./Includes/FooterUser";
import ProductLoader from "./Includes/ProductLoader";
import UserListService from "../../services/user-list.service";

import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import { useFormik } from "formik";
import { quizSearch } from "../../schema";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import $ from "jquery";
import { toast } from "react-toastify";
import { actionCreaters } from "../../Redux";
import { bindActionCreators } from "redux";
import Shipping from "./Includes/Shipping";
import { Tooltip } from "react-tooltip";
import SingleProductsWithVariants from "./SingleProductsWithVariants";

export default function ProductsPage() {
  const navigate = useNavigate();
  const [tableLoader, settableLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(false);

  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("20");

  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("0");
  const state = useSelector((state) => state.stateVals);
  const { certificate } = state;
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const productList = async (voucher, limit, page, values, direction, col) => {
    setLimit(limit);
    settableLoader(true);

    try {
      const responce = await UserListService.getProductsByVucher(
        voucher,
        limit,
        page,
        values,
        direction,
        col
      );

      const res = responce?.data?.response?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          id: value.id,
          image: value.image,
          description: value.description,
          points: value.points,
          has_variants: value.variant_id,
          variations: value.variations,

          name: value.name,
        });
      });

      settableData([...results]);
      setTotalPages(responce?.data?.response?.total_pages);
      setTotalResults(responce?.data?.response?.records);
      setCurrentPage(responce?.data?.response?.current_page);
      setLimit(responce?.data?.response?.per_page);
      settableLoader(false);
      $(".l-search").removeClass("show");
    } catch (err) {
      console.log(err);

      settableData([]);
      setTotalPages("0");
      setTotalResults("0");
      setLimit("20");
      setCurrentPage(1);
      settableLoader(false);
    }
  };

  useLayoutEffect(() => {
    if (certificate) {
      productList(
        certificate,
        limit,
        currentPage,
        values,
        values.direction,
        values.col
      );
      setShow(true);
    } else {
      navigate("/", { replace: true });
      setTimeout(() => {
        setShow(true);
      }, 500);
    }
  }, []);

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      fieldtype: "name",
      searchval: "",
      direction: "asc",
      col: "",
    },
    validationSchema: quizSearch,
    onSubmit: (values) => {
      productList(
        certificate,
        limit,
        "1",
        values,
        values.direction,
        values.col
      );
    },
  });
  const handleFilterMenu = () => {
    $(".l-search").toggleClass("show");
  };

  const productAddInCart = async (prdID, size = "", color = "") => {
    try {
      setDisable(true);
      const responce = await UserListService.addInCart(
        prdID,
        certificate,
        size,
        color
      );

      toast.success("Added to cart", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      userActions.cartCounter(certificate, "cartCounter");
      setDisable(false);
    } catch (err) {
      setDisable(false);

      // toast.warn("You cannot add more than One Product in the Cart.", {
      //   position: "top-center",
      //   autoClose: 3000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "dark",
      // });
      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.warn(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  return show ? (
    <div className="reward-body">
      <HeaderUser />
      <div className="main-content">
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <div className="l-search">
                <form onSubmit={handleSubmit} noValidate>
                  <h3>
                    Search
                    <button
                      className="d-md-none filter-button"
                      onClick={handleFilterMenu}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                      </svg>
                    </button>
                    <div className="clearfix"></div>
                  </h3>
                  <div className="s-out">
                    <div className="row gy-2">
                      <div className="col-lg-6">
                        <label>Search</label>
                        <div className="input-group">
                          <input
                            type={values.fieldtype === "date" ? "date" : "text"}
                            className={`form-control ${
                              errors.searchval && touched.searchval
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            name="searchval"
                            value={values.searchval || ""}
                            placeholder="What are you looking for?"
                          />

                          <input type="submit" value="Go" className="button" />
                          <input
                            type="reset"
                            value="Reset"
                            className="button"
                            onClick={() => window.location.reload(false)}
                          />
                        </div>

                        {errors.searchval && touched.searchval ? (
                          <span className="text-danger">
                            {errors.searchval}
                          </span>
                        ) : null}
                      </div>
                      <div className="col-lg-2 ">
                        <label>Sort By Column</label>
                        <select
                          className="form-select"
                          name="col"
                          value={values.col || ""}
                          onChange={(e) => {
                            handleChange(e);

                            productList(
                              certificate,
                              limit,
                              currentPage,
                              values,
                              values.direction,
                              e.target.value
                            );
                          }}
                        >
                          <option value="">Select Column</option>
                          <option value="name">Product Name</option>
                          <option value="points">Points</option>
                        </select>
                      </div>
                      <div className="col-lg-2 ">
                        <label>Sort Direction</label>
                        <select
                          className="form-select"
                          name="direction"
                          value={values.direction || ""}
                          onChange={(e) => {
                            handleChange(e);
                            productList(
                              certificate,
                              limit,
                              currentPage,
                              values,
                              e.target.value,
                              values.col
                            );
                          }}
                        >
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>
                      </div>

                      <div className="col-lg-2 ">
                        <label>Show </label>
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) =>
                            productList(
                              certificate,
                              e.target.value,
                              "1",
                              values,
                              values.direction,
                              values.col
                            )
                          }
                        >
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="user-form home_div">
                <Shipping />
                <h3>
                  All Products
                  <button
                    className="d-md-none filter-button"
                    onClick={handleFilterMenu}
                  >
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M11.36 20.213l-2.36 .787v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414"></path>
                      <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                      <path d="M20.2 20.2l1.8 1.8"></path>
                    </svg>
                  </button>
                  <div className="clearfix"></div>
                </h3>
                <div className="col-md-12">
                  <div className="row">
                    {tableLoader ? (
                      <div className="col-12">
                        <Skeleton
                          inline={true}
                          width={
                            window.innerWidth > 992
                              ? "24%"
                              : window.innerWidth > 767
                              ? "48%"
                              : "99%"
                          }
                          count={window.innerWidth > 992 ? 12 : 6}
                          height={
                            window.innerWidth > 992
                              ? "250px"
                              : window.innerWidth > 767
                              ? "250px"
                              : "150px"
                          }
                          style={{
                            margin: "5px",
                          }}
                        />
                      </div>
                    ) : tableData.length ? (
                      tableData.map((el) => (
                        <div className="col-xl-3 col-lg-6 col-md-6" key={el.id}>
                          {el.has_variants ? (
                            <SingleProductsWithVariants
                              el={el}
                              productAddInCart={productAddInCart}
                              disable={disable}
                            />
                          ) : (
                            <div className="product-wrap">
                              <div className="product-img">
                                <Link to={`/product-detail/${el.id}`}>
                                  <ProductLoader cardImg={el.image} />
                                </Link>
                                <div className={`controls `}>
                                  <button
                                    onClick={() => {
                                      productAddInCart(el.id);
                                    }}
                                    disabled={disable}
                                    data-tooltip-id={el.id}
                                    data-tooltip-content="Add to Cart!"
                                    data-tooltip-place="top"
                                  >
                                    <i className="fa fa-shopping-cart"></i>
                                  </button>

                                  <Tooltip
                                    id={el.id}
                                    style={{
                                      padding: "2px 10px",
                                    }}
                                  />

                                  <Link
                                    to={`/product-detail/${el.id}`}
                                    data-tooltip-id={el.id}
                                    data-tooltip-content={"View Details!"}
                                    data-tooltip-place="top"
                                  >
                                    <i className="fa fa-external-link"></i>
                                  </Link>
                                </div>
                              </div>
                              <div className="product-name">
                                <span title={el.name}>{el.name}</span>
                                {/* <span className="price">Points: {el.points}</span> */}
                              </div>
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="col-md-12">
                        <div
                          className="alert alert-warning d-flex align-items-center"
                          role="alert"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-exclamation-triangle-fill me-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                          </svg>

                          <div>
                            <span className="fw-bold">Warning!</span> No Records
                            Found.
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {totalResults > limit && totalPages > 1 ? (
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={parseInt(limit)}
                      totalItemsCount={totalResults}
                      onChange={(e) => {
                        productList(
                          certificate,
                          limit,
                          e,
                          values,
                          values.direction,
                          values.col
                        );
                      }}
                      pageRangeDisplayed={8}
                      itemClass="page-item"
                      linkClass="page-link"
                      firstPageText="First"
                      lastPageText="Last"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterUser />
    </div>
  ) : (
    <div className="white-screen"></div>
  );
}
